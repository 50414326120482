import { AmfSummaryDataBlockV1 } from '@deecision/dna-interfaces';
import { Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

function AmfSummaryTooltip(props: {
    dataBlock: AmfSummaryDataBlockV1,
}): ReactElement {
    const { t } = useTranslation();

    return <Stack spacing={1}>

        {(props.dataBlock.sanctions ?? []).map(sanction => {
            return (
                <Typography key={sanction.amfId}>
                    {t(`amf.summaryTooltip.sanction`)}&nbsp;
                    {sanction.downloadUrl
                        ? (<Link sx={{
                            color: 'primary.light',
                        }} href={sanction.downloadUrl} target="_blank">{sanction.amfId}</Link>)
                        : sanction.amfId
                    } - { sanction.theme} - { new Date(sanction.date).toLocaleDateString( )}
                </Typography>
            )
        })}

        {(props.dataBlock.transactions ?? []).map(transaction => {
            return (
                <Typography key={transaction.amfId}>
                    {t(`amf.summaryTooltip.transaction`)}&nbsp;
                    {transaction.downloadUrl
                        ? (<Link sx={{
                            color: 'primary.light',
                        }} href={transaction.downloadUrl} target="_blank">{transaction.amfId}</Link>)
                        : transaction.amfId } - { transaction.theme} - {new Date(transaction.date).toLocaleDateString()}
                </Typography>
            )
        })}
    </Stack>;
}

export default AmfSummaryTooltip;
