import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { AmfSummaryDataBlockV1, CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { IconAlertTriangleFilled, IconDatabase, IconHome, IconRefresh } from '@tabler/icons-react';
import { upperFirst } from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import CountriesFlags from '@/components/countriesflags';
import { CompanyGetter } from '@/main/providers/getter';
import Status from '@/components/status';
import AmfSummaryTooltip from '../tooltips/amf-summary-tooltip';

function CompanyEntitiesChips(props: { entity: CompanyEntity, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity, setEntity] = useState<CompanyEntity>(props.entity as CompanyEntity);
  const companyGetter = useMemo(() => new CompanyGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  useEffect(() => {
    setEntity(props.entity as CompanyEntity);
  }, [props.entity]);

  return ([
    <Tooltip
      key='common.identity.country'
      title={`${t('common.identity.country')}: ${companyGetter.getCountry()}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {CountriesFlags({ countries: [companyGetter.getCountryCode(true) || ''], size: props.size })}
          </Stack>
        }
        icon={<IconHome size={props.size === 'small' ? '1.1rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Chip
      key='state'
      label={upperFirst(companyGetter.getState())}
      color={companyGetter.getState() === 'open' ? 'success' : 'error'}
      size={props.size}
    />,
    <Tooltip key='completenessTitleChip' title={t('common.utils.completeness')} arrow placement='top'>
      <Chip
        label={
          <Stack direction='row' spacing={1} alignItems='center'>
            <IconDatabase size='1.2rem' />
            <Typography>{`${(companyGetter.getCompanyCompleteness() * 100).toFixed(2)}%`}</Typography>
          </Stack>
        }
        color='primary'
        size={props.size}
      />
    </Tooltip>,
    ...companyGetter.getTags().filter(tag => tag.family !== 'batchTag').map(tag => (
      <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family === 'companyType' && tag.value === 'listedCompany' ? t('entities.tags.listed.company') : t(`tags.families.${tag.family}`)} arrow placement='top'>
        {tag.family === 'companyType' && tag.value === 'listedCompany' ?
          <Chip sx={{ '& .MuiChip-label': { fontWeight: '600', pl: 4, pr: 4 } }} color='error' size={props.size} label='L' /> :
          <Chip label={t(`tags.values.${tag.value}`)} size={props.size} />
        }
      </Tooltip>
    )), <Tooltip
      key='veegilenz-chip'
      title={
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body2'>{companyGetter.getVeegilenzReason() !== t('common.utils.unknown') ? t(`veegilenz.reason.${companyGetter.getVeegilenzReason() || 'noReason'}`) : t('veegilenz.errorResponse.notSearched')}</Typography>
          {companyGetter.getVeegilenzReason() !== t('common.utils.unknown') &&
            <Status
              statusAsInt={
                companyGetter.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
                companyGetter.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
                companyGetter.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
                companyGetter.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
                companyGetter.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
                0
              }
              size='1rem'
            />
          }
        </Stack>
      }
      arrow
      placement='top'
    >
      <Chip
        label={t('veegilenz.label')}
        icon={<Box pl={1} display='flex' justifyContent='center'>
          <Status statusAsInt={companyGetter.getVeegilenzLevel(true) as number} size={props.size === 'small' ? '1.1rem' : '1.6rem'} dark color={companyGetter.getVeegilenzLevel() === t('common.utils.unknown') ? theme.palette.grey[700] : undefined} />
        </Box>}
        color={companyGetter.getVeegilenzLevel(true) === 2 ? 'error' : companyGetter.getVeegilenzLevel(true) === 1 ? 'warning' : companyGetter.getVeegilenzLevel() === t('common.utils.unknown') ? undefined : companyGetter.getVeegilenzLevel(true) === 0 ? 'success' : undefined}
        sx={{
          bgcolor: companyGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[800] : undefined,
          '& .MuiChip-label': {
            color: companyGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[200] : undefined
          }
        }}
        size={props.size}
      />
    </Tooltip>,
    ...companyGetter.getSanctions().map(sanction => (
      <Tooltip key={sanction} title={t(`veegilenz.sanctionType.${sanction}`)} arrow placement='top'>
        <Chip key={`${sanction}-chip`} icon={<IconAlertTriangleFilled />} label={t(`veegilenz.sanctionType.${sanction}`)} size={props.size} color='error' />
      </Tooltip>
    )),
    companyGetter.isAmfRelated()
      ?
      <Tooltip key='amf-chip' title={<AmfSummaryTooltip dataBlock={companyGetter.getAmfSummary() as AmfSummaryDataBlockV1} />} arrow placement='top'>
        <Chip
          color='error'
          label={
            <Stack spacing={1} direction='row' alignItems='center'>
              <IconAlertTriangleFilled
                size='1rem'
                color={theme.palette.error.dark}
              />
              <Typography>{t('amf.chip.label')}</Typography>
            </Stack>
          }
        />
      </Tooltip>
      : <></>, 
    companyGetter.getHighestPoliticalExposureLevel()
      ? <Tooltip key='pep-chip' title={t(`politicalExposure.label`)} arrow placement='top'>
        <Chip
          key='pep-chip'
          size={props.size}
          color='error'
          label={`${t(`politicalExposure.shortLabel`)} ${t(`politicalExposure.values.${companyGetter.getHighestPoliticalExposureLevel()}`)}`} />
      </Tooltip>
      : <></>,
    <Tooltip
      key='common.dates.lastUpdate'
      title={t('common.dates.lastUpdate')}
      arrow
      placement='top'
    >
      <Chip
        label={companyGetter.getDataDate()}
        icon={<IconRefresh size={props.size === 'small' ? '1.1rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>
  ]);
}

export default CompanyEntitiesChips;
